import moment from 'moment'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { i18n, LANGUAGES_MAP, Translation } from '../../common'

const Footer = () => {
  const history = useHistory()

  const onChangeLanguage = async (lang = 'ru') => {
    await i18n.changeLanguage(lang)
    history.go(0)
  }

  return (
    <div className='footer'>
      <div className='footer-links'>
        <Translation ns='footer'>
          {t => (
            <>
              <Link to='/contacts'>{t('contacts')}</Link>
              &nbsp;|&nbsp;
              <Link to='/feedback'>{t('feedback')}</Link>
              &nbsp;|&nbsp;
              <Link to='/subscribe'>{t('newsletter')}</Link>
              &nbsp;|&nbsp;
              <Link to='/ad-info'>{t('ad-info')}</Link>
              &nbsp;|&nbsp;
              <Link to='/faq'>{t('faq')}</Link>
              &nbsp;|&nbsp;
              <Link to='/vacancy'>{t('vacancies')}</Link>
              &nbsp;|&nbsp;
              <Link to='/rules'>{t('rules')}</Link>
              {(i18n.languages.length > 1 &&
                i18n.languages.map(lng => (
                  <React.Fragment key={lng}>
                    &nbsp;|&nbsp;
                    <a onClick={() => onChangeLanguage(lng)}>
                      {LANGUAGES_MAP[lng]}
                    </a>
                  </React.Fragment>
                ))) ||
                null}
            </>
          )}
        </Translation>
      </div>
      <div className='footer-brand'>&copy; Cinematica {moment().year()}</div>
    </div>
  )
}

export default Footer
