/**
 * Created by Eldar on 8/7/17.
 */

import React, { Component } from 'react'
import { observer } from 'mobx-react'
import ApiImage from '../ui/ApiImage'
import { Link } from 'react-router-dom'
import { Translation } from '../../../common'

@observer
export default class LatestNews extends Component {
  render() {
    const { items } = this.props
    return (
      <div className='announce-main'>
        <h4 className='text-center'>
          <Translation ns='home'>{t => t('news')}</Translation>
        </h4>
        <hr />
        <div className='announce-body'>
          {items.map(announce => (
            <div key={announce.id} className='announce-item'>
              <Link to={'/news/' + announce.id}>
                <ApiImage src={announce.file_image} />
                <div className='announce-title small text-center'>
                  {announce.title}
                </div>
              </Link>
            </div>
          ))}
        </div>
        <div className='announce-all-link small'>
          <Link to='/news'>
            <Translation ns='home'>{t => t('all-announcements')}</Translation>
          </Link>
        </div>
      </div>
    )
  }
}
