import React from 'react'
import { Button } from 'react-bootstrap'
import { Translation } from 'react-i18next'

export default function Dis({ url, params }) {
  return (
    <div className='p-4 d-flex align-items-center justify-content-center'>
      <form action={url} target='_blank' method='post'>
        <input type='hidden' name='param' value={JSON.stringify(params)} />
        <Button type='submit' variant='link'>
          <Translation ns='payment' keyPrefix={'dis'}>
            {t => t('continue')}
          </Translation>
        </Button>
      </form>
    </div>
  )
}
