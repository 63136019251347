import { action, observable } from 'mobx'
import BaseStore from './BaseStore'
import requester from '../common/requester'

export class GenericStore extends BaseStore {
  @observable selections = observable.array([])
  @observable deleting = false

  @action clearSelections() {
    this.selections.clear()
  }

  @action.bound
  selectAll() {
    let selections = []
    for (const item of this.items) {
      selections.push(item.id)
    }
    this.selections.replace(selections)
  }

  @action.bound
  unSelectAll() {
    this.selections.clear()
  }

  @action setDeleting(status) {
    this.deleting = status
  }

  async deleteSelections(url) {
    if (this.deleting || this.selections.length === 0) return
    this.setDeleting(true)
    try {
      const data = await requester.post(url + '/deletion', {
        items: this.selections,
      })
      this.clearSelections()
      return data
    } finally {
      this.setDeleting(false)
    }
  }
}

export class AdminStore extends BaseStore {
  @observable sections = observable.array([])
  @observable roles = observable.array([])

  async getSections() {
    let { data } = await requester.get('/admin')
    this.setSections(data.list)
  }

  @action setSections(data) {
    this.sections.replace(data)
  }

  async getRoles() {
    let { data } = await requester.get('/user/roles')
    this.setRoles(data.list)
  }

  @action setRoles(data) {
    this.roles.replace(data)
  }
}
