import axios from 'axios'
import { API_URL } from './api-url'

const requester = {
  cancelPoll: true,
  cancelPayment: true,
  get: function (url, params) {
    return axios.get(`${API_URL}/api/v1${url}`, { params: params })
  },
  post: function (url, data, version = 1) {
    return axios.post(`${API_URL}/api/v${version}${url}`, data)
  },
  put: function (url, data) {
    return axios.put(`${API_URL}/api/v1${url}`, data)
  },
  delete: function (url, params) {
    return axios.delete(`${API_URL}/api/v1${url}`, { params: params })
  },
  poll: async function (id) {
    this.cancelPoll = false
    let data = null
    let sleep = ms => {
      return new Promise(resolve => setTimeout(resolve, ms))
    }
    do {
      await sleep(1000)
      let resp = await axios.get(`${API_URL}/api/v1/payment/poll/${id}`)
      data = resp.data
      console.log(data)
    } while (!requester.cancelPoll && data.status === 0)
    return { data }
  },
  payment: async function (id) {
    this.cancelPayment = false
    let data = null
    let sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    let status = async () => {
      while (true) {
        let attempts = 1
        try {
          let resp = await axios.get(`${API_URL}/api/v1/payment/status/${id}`)
          return resp.data
        } catch {
          await sleep(attempts * 1000)
          attempts++
        }
      }
    }
    do {
      await sleep(1000)
      data = await status()
    } while (!requester.cancelPayment && data.status === 'pending')
    return { data }
  },
}

export default requester
