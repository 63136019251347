import BaseStore from './BaseStore'
import { computed, observable } from 'mobx'

export default class NotificationsStore extends BaseStore {
  @observable notification_types = {
    common: 'Обычный',
    movie_repertories: 'Уведомления о репертуарах',
    movie_reservation: 'Уведомления о брони',
    movie_purchase: 'Уведомления об оценке',
  }

  @computed get notification_type_options() {
    return Object.entries(this.notification_types).map(([value, label]) => ({
      value,
      label,
    }))
  }
}
