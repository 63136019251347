import autobind from 'autobind-decorator'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import {
  FaBullhorn,
  FaCreditCard,
  FaFilm,
  FaUser,
  FaVideo,
} from 'react-icons/fa'
import { MdClose, MdMenu } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { i18n, Translation } from '../../common'
import { COUNTRY } from '../../common/country'
import ActiveLink from './ui/ActiveLink'
import Button from './ui/Button'

@inject('store')
@observer
export default class TopBar extends Component {
  constructor(props) {
    super(props)
    this.store = this.props.store.appState
    this.homeStore = this.props.store.homeStore
    this.state = {
      hidden: 'menu-hidden',
    }
  }

  render() {
    const { authenticated, user, card, account } = this.store
    let auth_link = null
    if (authenticated) {
      if (user)
        auth_link = (
          <ActiveLink to='/admin'>
            <FaUser />
            <Translation ns='top-bar'>{t => t('admin')}</Translation>
          </ActiveLink>
        )
      else if (card)
        auth_link = (
          <ActiveLink to='/card'>
            <FaCreditCard />
            <Translation ns='top-bar'>{t => t('card')}</Translation>
          </ActiveLink>
        )
      else if (account)
        auth_link = (
          <ActiveLink to='/account'>
            <FaUser />
            <Translation ns='top-bar'>{t => t('accounts')}</Translation>
          </ActiveLink>
        )
    }
    return (
      <div className={'topbar ' + this.state.hidden}>
        <nav>
          <a
            role='button'
            href='#'
            className='show-menu-btn'
            onClick={this.toggleMenu}
          >
            <MdMenu color='#fff' size={24} />
          </a>
          <div className='menu-buttons' onClick={this.hideMenu}>
            <ActiveLink
              activeOnlyWhenExact={true}
              to='/'
              className='no_background'
            >
              <div
                className={`cinematica-logo country-${COUNTRY}`}
                onClick={this.qpRefresh}
              />
            </ActiveLink>
            <ActiveLink activeOnlyWhenExact={false} to='/imax'>
              <div className={`imax-btn country-${COUNTRY}`} />
            </ActiveLink>
            <ActiveLink activeOnlyWhenExact={false} to='/atmos'>
              <div className={`atmos-btn country-${COUNTRY}`} />
            </ActiveLink>
            {COUNTRY === 'uz' && (
              <ActiveLink activeOnlyWhenExact={false} to='/vip'>
                <div className={`vip-btn country-${COUNTRY}`} />
              </ActiveLink>
            )}
            <ActiveLink activeOnlyWhenExact={false} to='/movies'>
              <FaFilm />
              <Translation ns='top-bar'>{t => t('movies')}</Translation>
            </ActiveLink>
            <ActiveLink activeOnlyWhenExact={false} to='/cinema'>
              <FaVideo />
              <Translation ns='top-bar'>{t => t('cinemas')}</Translation>
            </ActiveLink>
            <ActiveLink activeOnlyWhenExact={false} to='/news'>
              <FaBullhorn />
              <Translation ns='top-bar'>{t => t('announcements')}</Translation>
            </ActiveLink>
            {auth_link}
          </div>
          <a
            role='button'
            href='#'
            className='close-menu-btn'
            onClick={this.hideMenu}
          >
            <MdClose color='#fff' size={48} />
          </a>
        </nav>
        <div className='sign_in_button' onClick={this.hideMenu}>
          {authenticated ? (
            <Button
              onClick={this.store.manualLogOut}
              title={i18n.t('logout', { ns: 'top-bar' })}
            />
          ) : (
            <Link to='/login' className='button'>
              <Translation ns='top-bar'>{t => t('login')}</Translation>
            </Link>
          )}
        </div>
      </div>
    )
  }

  @autobind
  hideMenu(e) {
    e.stopPropagation()
    this.setState({ hidden: 'menu-hidden' })
    return false
  }

  @autobind
  toggleMenu(e) {
    e.stopPropagation()
    this.setState({
      hidden: this.state.hidden === 'menu-hidden' ? '' : 'menu-hidden',
    })
    return false
  }

  @autobind
  qpRefresh() {
    this.homeStore.qp_view = false
    this.homeStore.qp_selection = null
  }
}
