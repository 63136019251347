import React from 'react'
import { Helmet } from 'react-helmet'
import { COUNTRY } from '../../common/country'

const city = COUNTRY === 'kg' ? 'Бишкеке' : 'Ташкенте'
export const AppHelmet = () => {
  return (
    <Helmet>
      <title>Синематика - сеть кинотеатров в {city}</title>
    </Helmet>
  )
}
