import { action, computed, observable } from 'mobx'
import requester from '../common/requester'

export default class BaseStore {
  @observable items = observable.array([])
  @observable structure = observable.array([])
  @observable access = observable.array([])
  @observable item = null
  @observable filters = observable.object({})
  @observable order = null
  @observable page = 1
  @observable total_items = 0
  @observable postDataStatus = null

  async fetchData(match) {
    let pathname = match.url
    let params = {}
    if (match.path && !match.path.endsWith('/:id')) {
      params.page = this.page
      Object.keys(this.filters)
        .filter(k => {
          const type = typeof this.filters[k]
          return !(
            ['string', 'undefined', 'null'].includes(type) && !this.filters[k]
          )
        })
        .map(key => (params[`filter[${key}]`] = this.filters[key]))
      if (this.order) params.order = this.order
    }
    const response = await requester.get(pathname, params)
    const { data } = response
    if (data.structure) this.setStructure(data.structure)
    if (data.access) this.setAccess(data.access)
    if (data.list) {
      this.setData(data.list, data.count)
    } else {
      data.item ? this.setSingle(data.item) : this.setSingle(data)
    }
    return response
  }

  async postData(pathname, item = this.item) {
    try {
      await Promise.all(
        Object.keys(item)
          .filter(k => k.startsWith('file_'))
          .map(async k => {
            if (item[k] instanceof File) {
              let form = new FormData()
              form.append('file', item[k])
              let { data } = await requester.put(`${pathname}`, form)
              item[k] = data.link
            }
          }),
      )
      let { data } = await requester.post(`${pathname}`, item)
      item.id = data.id
      this.setPostDataStatus({ level: 'success', message: 'Данные сохранены' })
    } catch (e) {
      this.setPostDataStatus({ level: 'danger', message: e.message || e })
    }
  }

  async deleteData(pathname) {
    return await requester.delete(pathname)
  }

  @action setPostDataStatus(status) {
    this.postDataStatus = status
  }

  @action setData(data, count) {
    this.items.replace(data)
    this.total_items = count || this.items.length
  }

  @action setSingle(data) {
    this.item = data
  }

  @action clearItems() {
    this.items.clear()
    this.structure.clear()
    this.item = null
  }

  @action clearItem() {
    this.item = null
    this.setPostDataStatus(null)
  }

  @action clearFilter() {
    this.filters = {}
  }

  @action clearOrder() {
    this.order = null
  }

  @action setStructure(s) {
    this.structure.replace(s)
  }

  @action setAccess(a) {
    this.access.replace(a)
  }

  @action setFilter(key, value) {
    this.filters[key] = value
  }

  @action setOrder(column, dir) {
    this.order = `${column} ${dir ? dir : 'asc'}`
  }

  getOrder() {
    if (!this.order) return {}
    let index = this.order.lastIndexOf(' ')
    let column = this.order.slice(0, index)
    let dir = this.order.slice(index + 1)
    return { [column]: dir }
  }

  @action setPage(p) {
    this.page = p
  }

  @computed get pagesCount() {
    return Math.ceil(this.total_items / 40)
  }
}
