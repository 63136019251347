import React from 'react'
import { Spinner } from 'react-bootstrap'

export default class Frame3D extends React.Component {
  frame = React.createRef()

  shouldComponentUpdate({ url, params }) {
    return (
      this.props.url !== url &&
      JSON.stringify(this.props.params) === JSON.stringify(params)
    )
  }

  componentDidMount() {
    if (!this.frame.current) return
    const { current } = this.frame
    let { url, params } = this.props
    let doc = current.contentDocument
    let form = document.createElement('form')
    form.setAttribute('method', params ? 'post' : 'get')
    let [path, ...rest] = url.split('?')
    form.setAttribute('action', path)

    const addHidden = (key, value) => {
      let hiddenField = document.createElement('input')
      hiddenField.setAttribute('type', 'hidden')
      hiddenField.setAttribute('name', key)
      hiddenField.setAttribute('value', value)
      form.appendChild(hiddenField)
    }

    rest.forEach(p =>
      p.split('&').forEach(q => {
        const [key, ...v] = q.split('=')
        key && addHidden(key, v.join('='))
      }),
    )

    params && Object.keys(params).forEach(key => addHidden(key, params[key]))

    doc.body.appendChild(form)
    form.submit()
    current.scrollIntoView()
  }

  render() {
    const { height = 600 } = this.props
    // noinspection HtmlDeprecatedAttribute
    return (
      <div className='position-relative'>
        <iframe
          ref={this.frame}
          id='s3d'
          scrolling='no'
          width='100%'
          height={height}
          frameBorder='0'
        />
      </div>
    )
  }
}
