import { action, computed, observable } from 'mobx'
import moment from 'moment'
import requester from '../common/requester'
import BaseModel from './BaseModel'
import BaseStore from './BaseStore'

export default class CinemaStore extends BaseStore {
  @observable repertories = {}
  @observable halls = observable.array([])

  @action setRepertories(data) {
    this.repertories = data
  }

  async getRepertories() {
    let { data } = await requester.get(
      `/repertory/cinema/${this.item.id}/grouped`,
    )
    data.list.forEach(
      item =>
        (item.datetime = moment(`${item.date} ${item.time}`, 'DD.MM.YY HH:mm')
          .subtract(8, 'hours')
          .toISOString()),
    )
    let dates = data.list.reduce((dates, item) => {
      const date = moment(item.datetime).format('DD.MM.YY')
      return { ...dates, [date]: [...(dates[date] || []), item] }
    }, {})
    this.setRepertories(dates)
  }

  @action setHalls(data) {
    this.halls.replace(data)
  }

  async getHalls() {
    let { data } = await requester.get(`/cinema/halls`)
    this.setHalls(data.list)
  }

  @computed get repertoryDates() {
    return Object.keys(this.repertories).sort((a, b) => {
      let d1 = moment(a, 'DD.MM.YY').toDate()
      let d2 = moment(b, 'DD.MM.YY').toDate()
      return d1 > d2 ? 1 : d2 > d1 ? -1 : 0
    })
  }

  @action clearItem() {
    this.repertories = {}
    super.clearItem()
  }
}

export class Cinema extends BaseModel {}
