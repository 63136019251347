import React from 'react'
import { Route, Link } from 'react-router-dom'

const ActiveLink = ({ to, activeOnlyWhenExact, className, ...rest }) => (
  <Route
    path={to}
    exact={activeOnlyWhenExact}
    children={({ match }) => (
      <Link
        to={to}
        {...rest}
        className={(match ? 'active ' : '') + (className || '')}
      />
    )}
  />
)

export default ActiveLink
