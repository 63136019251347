import autobind from 'autobind-decorator'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Ratio } from 'react-bootstrap'
import videojs from 'video.js'
import 'video.js/dist/video-js.min.css'
import { API_URL } from '../../../common'

if (!videojs.getPlugin('closeButton')) {
  videojs.registerPlugin('closeButton', function (options) {
    this.addChild('CloseButton', options)
  })
}

export default class VideoPlayer extends Component {
  componentDidMount() {
    // instantiate video.js
    let { sources, close, onVideoEnded, ...rest } = this.props
    sources = sources.map(s => {
      return {
        src:
          s.src.startsWith('http://') ||
          s.src.startsWith('https://') ||
          s.src.startsWith('blob:')
            ? s.src
            : API_URL + s.src,
        type: s.type,
      }
    })
    this.player = videojs(this.videoNode, { sources: sources, ...rest }, () => {
      this.player.on('ended', this.onVideoEnded)
      if (close === true)
        this.player.on(
          this.player.getChild('closeButton'),
          'close',
          this.onVideoClose,
        )
    })
    if (close === true) this.player.closeButton()
  }

  @autobind
  onVideoEnded() {
    this.props.onVideoEnded && this.props.onVideoEnded()
  }

  @autobind
  onVideoClose() {
    this.props.onVideoEnded && this.props.onVideoEnded()
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose()
    }
    this.player.off('ended', this.onVideoEnded)
  }

  render() {
    return (
      <div data-vjs-player>
        <video ref={node => (this.videoNode = node)} className='video-js' />
      </div>
    )
  }
}

VideoPlayer.propTypes = {
  onVideoEnded: PropTypes.func,
}

export class VideoPlayerEmbed extends Component {
  render() {
    return (
      <Ratio aspectRatio='16x9'>
        <VideoPlayer {...this.props} />
      </Ratio>
    )
  }
}
