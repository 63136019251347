import React from 'react'
import { Button } from 'react-bootstrap'
import { Translation } from 'react-i18next'

export default function Payze({ url }) {
  return (
    <div className='p-4 d-flex align-items-center justify-content-center'>
      <Button href={url} target='_blank' variant='link'>
        <Translation ns='payment' keyPrefix={'payze'}>
          {t => t('continue')}
        </Translation>
      </Button>
    </div>
  )
}
