/**
 * Created by jaynakus on 6/9/17.
 */
import { action, observable, observe } from 'mobx'
import requester from '../common/requester'
import { groupBy } from '../common/utils'

export default class HomeStore {
  @observable todayMovies = observable.array([])
  @observable soonMovies = observable.array([])
  @observable slider = observable.array([])
  @observable lastNews = observable.array([])
  @observable qp_view = false
  @observable qp_selection = null

  async getToday() {
    let { data } = await requester.get(`/movies/today`)
    this.setToday(data.list)
  }

  @action setToday(movies) {
    this.todayMovies.replace(movies)
  }

  async getSoon() {
    let { data } = await requester.get(`/movies/soon`)
    this.setSoon(data.list)
  }

  @action setSoon(movies) {
    this.soonMovies.replace(movies)
  }

  async getSlider() {
    let { data } = await requester.get(`/movies/slider`)
    this.setSlider(data.list)
  }

  @action setSlider(movies) {
    this.slider.replace(movies)
  }

  async getNews() {
    let { data } = await requester.get('/news')
    this.setNews(data.list)
  }

  @action setNews(news) {
    if (news.length > 5) news.length = 5
    this.lastNews.replace(news)
  }
}
