import i18n, { use } from 'i18next'
import {
  I18nextProvider,
  initReactI18next,
  Translation,
  useTranslation,
} from 'react-i18next'
import en from './en.json'
import ky from './ky.json'
import ru from './ru.json'
import uz from './uz.json'
import LanguageDetector from './LanguageDetecter'
import { LANGUAGES } from './languages'

const AVAILABLE_LANGUAGES = { ru, ky, en, uz }
const SUPPORTED_LANGUAGES = LANGUAGES.filter(lang => AVAILABLE_LANGUAGES[lang])

export const LANGUAGES_MAP = {
  ru: 'Рус',
  ky: 'Кырг',
  uz: "O'zb",
  en: 'Eng',
}

use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: Object.fromEntries(
      SUPPORTED_LANGUAGES.map(lng => [lng, AVAILABLE_LANGUAGES[lng]]),
    ),
    fallbackLng: SUPPORTED_LANGUAGES,
    supportedLngs: SUPPORTED_LANGUAGES,
    defaultNS: 'common',
    compatibilityJSON: 'v3',
    interpolation: { escapeValue: false },
  })
  .then(() => console.log('i18n ready'))

export { i18n, useTranslation, I18nextProvider, Translation }
