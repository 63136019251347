import { observable, action } from 'mobx'
import BaseStore from './BaseStore'
import requester from '../common/requester'

export default class BannerStore extends BaseStore {
  @observable banner = null

  @action setBanner(banner) {
    this.banner = banner
  }

  async getBanner() {
    try {
      let response = await requester.get('/banner')
      response.data && this.setBanner(response.data)
      return response.data
    } catch (e) {
      throw e
    }
  }
}
