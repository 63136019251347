import { store, rehydrate } from 'rfx-core'
import { configure } from 'mobx'

configure({ enforceActions: 'never' })

import { AdminStore, GenericStore } from './AdminStore'
import appState from './AppState'
import atmosStore from './AtmosStore'
import bannerStore from './BannerStore'
import cardStore from './CardStore'
import cinemaStore from './CinemaStore'
import faqStore from './FAQStore'
import feedbackStore from './FeedbackStore'
import homeStore from './HomeStore'
import imaxStore from './ImaxStore'
import vipStore from './VipStore'
import movieStore from './MovieStore'
import newsStore from './NewsStore'
import notificationsStore from './NotificationsStore'
import payStore from './PayStore'
import quickPayStore from './QuickPayStore'
import repertoryStore from './RepertoryStore'
import subscribeStore from './SubscribeStore'

export default () => {
  store.setup({
    adminStore: AdminStore,
    appState,
    atmosStore,
    bannerStore,
    cardStore,
    cinemaStore,
    editorStore: GenericStore,
    faqStore,
    feedbackStore,
    homeStore,
    imaxStore,
    vipStore,
    movieStore,
    newsStore,
    notificationsStore,
    payStore,
    quickPayStore,
    repertoryStore,
    subscribeStore,
  })
  return rehydrate()
}
