import { observable } from 'mobx'
import { GenericStore } from './AdminStore'
import requester from '../common/requester'
import autobind from 'autobind-decorator'

export default class RepertoryStore extends GenericStore {
  @observable cinemas_id = observable.array([])
  @observable repertory_date = ''

  constructor() {
    super()
  }

  @autobind
  async startPremiereJob() {
    await requester.post(`/admin/job/repertory`, {
      cinemas_id: [...this.cinemas_id],
      repertory_date: this.repertory_date ? this.repertory_date : undefined,
    })
  }
}
