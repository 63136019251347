import { COUNTRY } from '../common/country'

if (process.env.NODE_ENV === 'production' && COUNTRY !== 'kg') {
  window.dataLayer = window.dataLayer || []

  function gtag() {
    window.dataLayer.push(arguments)
  }

  gtag('js', new Date())
  gtag('config', 'G-4MF890S1BF')

  window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })

  const script = document.createElement('script')
  script.async = true
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-4MF890S1BF'

  let _script = document.getElementsByTagName('script')[0]
  _script.parentNode.insertBefore(script, _script)
}
