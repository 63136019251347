import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import React from 'react'
import { Popover } from 'react-bootstrap'
import { CURRENCY, i18n, Translation } from '../../../common'

export function pricePopover(repertory) {
  const id = `${repertory.cinema_id}-${repertory.hall_id}-${repertory.movie_id}`
  return (
    <Popover
      id={`popover-repertory-price-${id}`}
      title={i18n.t('price-ticket', { ns: 'movie' })}
    >
      <Popover.Header>
        <Translation ns={'movie'}>{t => t('price-ticket')}</Translation>
      </Popover.Header>
      <Popover.Body>
        <PricePopover repertory={repertory} />
      </Popover.Body>
    </Popover>
  )
}

@observer
class PricePopover extends React.Component {
  static propTypes = {
    repertory: PropTypes.shape({
      cinema_id: PropTypes.number.isRequired,
      hall_id: PropTypes.number.isRequired,
      movie_id: PropTypes.number.isRequired,
      discount_1: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      discount_2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }).isRequired,
  }

  render() {
    const { repertory } = this.props
    return (
      <table>
        <tbody>
          {repertory.discount_1 && (
            <tr>
              <td>
                <Translation ns={'movie'}>{t => t('child')}</Translation>
              </td>
              <td>
                {repertory.discount_1} {CURRENCY}
              </td>
            </tr>
          )}
          {repertory.discount_2 && (
            <tr>
              <td>
                <Translation ns={'movie'}>{t => t('student')}</Translation>
              </td>
              <td>
                {repertory.discount_2} {CURRENCY}
              </td>
            </tr>
          )}
          <tr>
            <td>
              <Translation ns={'movie'}>{t => t('adult')}</Translation>
            </td>
            <td>
              {repertory.price} {CURRENCY}
            </td>
          </tr>
        </tbody>
      </table>
    )
  }
}
