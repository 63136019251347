import { action, observable } from 'mobx'
import requester from '../common/requester'

export default class FAQStore {
  @observable item = {}
  @observable items = observable.array([])
  @observable loading_item = false
  @observable loading_items = false

  fetchItems = async () => {
    this.setLoadingItems(true)
    try {
      const data = await requester.get('/faq')
      this.setItems(data.data.list)
    } finally {
      this.setLoadingItems(false)
    }
  }

  fetchItem = async code => {
    this.setLoadingItem(true)
    try {
      const data = await requester.get('/faq/' + code)
      this.setItem(data.data.item)
    } finally {
      this.setLoadingItem(false)
    }
  }

  @action
  setLoadingItems = status => {
    this.loading_items = status
  }

  @action
  setLoadingItem = status => {
    this.loading_item = status
  }

  @action
  setItems = items => {
    this.items.replace(items)
  }

  @action
  setItem = item => {
    this.item = item || {}
  }

  clearItems = () => {
    this.setItems([])
  }

  clearItem = () => {
    this.setItem({})
  }
}
