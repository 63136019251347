import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FacebookIcon,
  FacebookShareButton,
  FacebookShareCount,
  OKIcon,
  OKShareButton,
  OKShareCount,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  VKIcon,
  VKShareButton,
  VKShareCount,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share'
import { COUNTRY } from '../../../common/country'

export default function SocialNetworkLinks() {
  const { t } = useTranslation('social')
  if (COUNTRY !== 'kg') return null
  return (
    <div className='b-fixed-social'>
      <ul>
        <li className='b-fixed-social__item'>
          <a
            href='https://www.facebook.com/cinematica.kg'
            target='_blank'
            className='b-social-link b-social-link_facebook'
            title={t('facebook', { ns: 'social' })}
          >
            <span className='b-social-icon b-social-icon_facebook' />
          </a>
        </li>
        <li className='b-fixed-social__item'>
          <a
            href='https://twitter.com/Cinematica_KG'
            target='_blank'
            className='b-social-link b-social-link_twitter'
            title={t('twitter', { ns: 'social' })}
          >
            <span className='b-social-icon b-social-icon_twitter' />
          </a>
        </li>
        <li className='b-fixed-social__item'>
          <a
            href='http://www.odnoklassniki.ru/cinematica.kg'
            target='_blank'
            className='b-social-link b-social-link_odnoklassniki'
            title={t('ok', { ns: 'social' })}
          >
            <span className='b-social-icon b-social-icon_odnoklassniki' />
          </a>
        </li>
        <li className='b-fixed-social__item'>
          <a
            href='http://instagram.com/cinematica_kg'
            target='_blank'
            className='b-social-link b-social-link_instagram'
            title={t('instagram', { ns: 'social' })}
          >
            <span className='b-social-icon b-social-icon_instagram' />
          </a>
        </li>
      </ul>
    </div>
  )
}

export class SocialShareLinks extends Component {
  render() {
    const { url, title, image } = this.props
    return (
      <div className='Share__container'>
        <div className='Share__some-network'>
          <FacebookShareButton
            url={url}
            quote={title}
            className='Share__some-network__share-button'
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>

          <FacebookShareCount
            url={url}
            className='Share__some-network__share-count'
          >
            {count => count}
          </FacebookShareCount>
        </div>

        <div className='Share__some-network'>
          <VKShareButton
            url={url}
            {...(image ? { image: image } : {})}
            windowWidth={660}
            windowHeight={460}
            className='Share__some-network__share-button'
          >
            <VKIcon size={32} round />
          </VKShareButton>

          <VKShareCount
            url={url}
            className='Share__some-network__share-count'
          />
        </div>

        <div className='Share__some-network'>
          <OKShareButton
            url={url}
            {...(image ? { image: image } : {})}
            windowWidth={660}
            windowHeight={460}
            className='Share__some-network__share-button'
          >
            <OKIcon size={32} round />
          </OKShareButton>

          <OKShareCount
            url={url}
            className='Share__some-network__share-count'
          />
        </div>

        <div className='Share__some-network'>
          <TwitterShareButton
            url={url}
            title={title}
            className='Share__some-network__share-button'
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>

          <div className='Share__some-network__share-count'>&nbsp;</div>
        </div>

        <div className='Share__some-network'>
          <TelegramShareButton
            url={url}
            title={title}
            className='Share__some-network__share-button'
          >
            <TelegramIcon size={32} round />
          </TelegramShareButton>

          <div className='Share__some-network__share-count'>&nbsp;</div>
        </div>

        <div className='Share__some-network'>
          <WhatsappShareButton
            url={url}
            title={title}
            separator=':: '
            className='Share__some-network__share-button'
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>

          <div className='Share__some-network__share-count'>&nbsp;</div>
        </div>
      </div>
    )
  }
}

SocialShareLinks.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
}
