import React from 'react'
import { Image } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { imageUrl } from '../../../common/utils'

export default class ApiImage extends React.Component {
  static propTypes = {
    src: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    placeholder: PropTypes.any,
  }

  static defaultProps = { width: null, height: null, placeholder: null }

  render() {
    let { src, width, height, placeholder, ...rest } = this.props
    if (!src) return placeholder
    return <Image {...rest} src={imageUrl(src, width, height)} />
  }
}
