import { action, observable } from 'mobx'
import BaseStore from './BaseStore'
import requester from '../common/requester'

export default class AtmosStore extends BaseStore {
  @observable page_repertory = ''
  @observable page_tech = ''

  loadPageData = async (page, callback) => {
    if (this[`loading_${page}_page`]) return
    this[`loading_${page}_page`] = true
    try {
      const data = await requester.get(`/page/atmos/${page}`)
      callback(data)
    } finally {
      this[`loading_${page}_page`] = false
    }
  }

  @action
  setPageData = (page, data) => {
    if (!this[`page_${page}`] && typeof $ !== 'undefined') {
      setTimeout(() => {
        if (data.css) {
          $(`<style type="text/css">${data.css}</style>`).appendTo('head')
        }
        if (data.js) {
          $(`<script type="text/javascript">${data.js}</script>`).appendTo(
            'body',
          )
        }
      }, 100)
    }
    this[`page_${page}`] = data.html
  }

  setRepertoryPage = ({ data }) => this.setPageData('repertory', data)

  getRepertoryPage = () => this.loadPageData('repertory', this.setRepertoryPage)

  setTechPage = ({ data }) => this.setPageData('tech', data)

  getTechPage = () => this.loadPageData('tech', this.setTechPage)
}
