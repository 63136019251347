import React, { Component } from 'react'
import { FaTimes } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { Translation } from '../../common'

export default class NotFound extends Component {
  render() {
    const { match } = this.props
    return !match || !match.url.endsWith('/404') ? (
      <div className='not-found'>
        <FaTimes />
        <label>
          <Translation ns='not-found'>{t => t('error')}</Translation>
        </label>
        <p>
          <Translation ns='not-found'>{t => t('not-find-info')}</Translation>
        </p>
        <Link className='feedbackBtn' to='/feedback'>
          <Translation ns='not-found'>{t => t('feedback')}</Translation>
        </Link>
      </div>
    ) : (
      <div className='page posts'>
        <div className='not-found'>
          <FaTimes />
          <label>
            <Translation ns='not-found'>{t => t('error')}</Translation>
          </label>
          <p>
            <Translation ns='not-found'>{t => t('page-not-found')}</Translation>
          </p>
          <Link className='feedbackBtn' to='/feedback'>
            <Translation ns='not-found'>{t => t('feedback')}</Translation>
          </Link>
        </div>
      </div>
    )
  }
}
