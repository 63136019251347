import autobind from 'autobind-decorator'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import { Col, FormGroup, Row, Stack } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { AutoButton, AutoForm, FormCheckbox, FormInput } from '../ui/FormInput'
import PaymentCancel from './PaymentCancel'
import { Translation, i18n } from '../../../common'

@inject('store')
@observer
export default class Balance extends Component {
  render() {
    let { payStore } = this.props.store
    return (
      <div>
        <Row>
          <Col xs={12} sm={3} />
          <Col xs={12} sm={6}>
            <div className='balance_logo' />
          </Col>
        </Row>
        <Row>
          {!payStore.balance.payment_id ? (
            <AutoForm style={{ marginTop: 15 }} onSubmit={this.pay}>
              <Stack direction='vertical' gap={2}>
                <Row>
                  <Col xs={12} sm={3} />
                  <Col xs={12} sm={6}>
                    <FormInput
                      placeholder={i18n.t('balance.phone', { ns: 'payment' })}
                      name='phone'
                      inputName='number'
                      model={payStore.balance}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={3} />
                  <Col xs={12} sm={6}>
                    <FormInput
                      placeholder={i18n.t('balance.email', { ns: 'payment' })}
                      type='email'
                      name='email'
                      inputName='email'
                      model={payStore}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={3} />
                  <Col xs={12} sm={6} className='text-center'>
                    <FormCheckbox
                      name='acceptBalance'
                      model={payStore}
                      required
                    >
                      <Translation ns='payment'>
                        {t => t('confirm')}
                      </Translation>{' '}
                      <Link
                        target='_blank'
                        to='/pay-agreement'
                        className='conditions'
                      >
                        <Translation ns='payment'>
                          {t => t('terms')}
                        </Translation>
                      </Link>
                      <Translation ns='payment'>{t => t('and')}</Translation>
                      <Link
                        target='_blank'
                        to='/ad-info'
                        className='conditions'
                      >
                        <Translation ns='payment'>
                          {t => t('ad-info')}
                        </Translation>
                      </Link>
                    </FormCheckbox>
                  </Col>
                </Row>
                <Row className='justify-content-center'>
                  <Col xs='auto'>
                    <AutoButton
                      type='submit'
                      disabled={!payStore.canPayBalance}
                    >
                      <Translation ns='payment'>
                        {t => t('start-pay')}
                      </Translation>
                    </AutoButton>
                  </Col>
                </Row>
              </Stack>
            </AutoForm>
          ) : (
            <AutoForm>
              <Stack direction='vertical' gap={2}>
                <Row>
                  <Col xs={12} sm={3} />
                  <Col xs={12} sm={6}>
                    <div className='padding'>
                      <FormGroup className='text-center'>
                        <FormInput
                          placeholder={i18n.t('balance.otp', { ns: 'payment' })}
                          name='otp'
                          inputName='otp'
                          model={payStore.balance}
                        />
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Row className='justify-content-center'>
                  <Col xs='auto'>
                    <Stack direction='horizontal' gap={2}>
                      <AutoButton
                        color='warning'
                        onClick={payStore.balanceRestart}
                      >
                        <Translation ns='payment'>{t => t('anew')}</Translation>
                      </AutoButton>
                      <AutoButton onClick={payStore.balanceFinish}>
                        <Translation ns='payment'>{t => t('pay')}</Translation>
                      </AutoButton>
                    </Stack>
                  </Col>
                </Row>
              </Stack>
              <PaymentCancel payment_id={payStore.balance.payment_id} />
            </AutoForm>
          )}
        </Row>
      </div>
    )
  }

  @autobind
  pay() {
    let { appState, payStore } = this.props.store
    const { card } = appState
    payStore
      .payByBalance(card ? card.code : undefined)
      .catch(this.props.onPayFailed)
  }
}
