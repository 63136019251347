import logo_3d from '../resources/logos/3dlogo.png'
import logo_imax from '../resources/logos/imaxlogo.png'
import logo_atmos from '../resources/logos/atmoslogo.png'
import logo_imax_3d from '../resources/logos/imax3dlogo.png'
import logo_atmos_3d from '../resources/logos/atmos3dlogo.png'
import logo_atmos_imax from '../resources/logos/atmosimaxlogo.png'
import logo_atmos_imax_3d from '../resources/logos/atmosimax3dlogo.png'

export const TYPE_ICONS = {
  '3d': logo_3d,
  imax: logo_imax,
  atmos: logo_atmos,
  '3d-imax': logo_imax_3d,
  '3d-atmos': logo_atmos_3d,
  'imax-atmos': logo_atmos_imax,
  '3d-imax-atmos': logo_atmos_imax_3d,
}
export const MOVIE_TYPES = ['3d', 'imax', 'atmos']
