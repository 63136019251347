import { action, observable } from 'mobx'
import requester from '../common/requester'

export default class CardStore {
  @observable item = null
  @observable items = observable.array([])

  @action
  spliceItem(r) {
    this.items.remove(r)
  }

  @action
  setItemRemoving(r, state = true) {
    r.removing = state
  }

  @action
  async fetchData(match) {
    const response = await requester.get(match.url)
    const { data } = response
    if (data.list) this.items.replace(data.list)
    if (!data.hasOwnProperty('list') || data.item)
      this.item = data.item ? data.item : data
    return response
  }

  @action setItems(items) {
    this.items = items
  }

  async fetchPurchases() {
    const response = await requester.get('/card/purchases')
    const { data } = response
    if (data.list) this.setItems(data.list)
  }

  @action
  clearItems() {
    this.items.clear()
  }

  @action
  clearItem() {
    this.item = null
  }
}
