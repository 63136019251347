import { action, observable } from 'mobx'
import requester from '../common/requester'
import BaseStore from './BaseStore'

export default class VipStore extends BaseStore {
  @observable page_repertory = ''
  @observable page_gallery = ''

  loadPageData = async (page, callback) => {
    if (this[`loading_${page}_page`]) return
    this[`loading_${page}_page`] = true
    try {
      const data = await requester.get(`/page/vip/${page}`)
      callback(data)
    } finally {
      this[`loading_${page}_page`] = false
    }
  }

  @action
  setPageData = (page, data) => {
    this[
      `page_${page}`
    ] = `<style type="text/css">${data.css}</style><script type="text/javascript">${data.js}</script>${data.html}`
  }

  setRepertoryPage = ({ data }) => this.setPageData('repertory', data)

  getRepertoryPage = () => this.loadPageData('repertory', this.setRepertoryPage)

  setGalleryPage = ({ data }) => this.setPageData('gallery', data)

  getGalleryPage = () => this.loadPageData('gallery', this.setGalleryPage)
}
