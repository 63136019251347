import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Carousel, Ratio, Tab, Tabs } from 'react-bootstrap'
import MoviesToday from './movieComp/MoviesToday'
import ApiImage from './ui/ApiImage'
import { Link } from 'react-router-dom'
import LatestNews from './newsComp/LatestNews'
import QuickPay from './QuickPay'
import { i18n } from '../../common'

@inject('store')
@observer
export default class Home extends Component {
  constructor(props) {
    super(props)
    this.store = this.props.store.homeStore
  }

  render() {
    let { todayMovies, soonMovies, slider, lastNews } = this.store
    return (
      <div className='page home'>
        <header>
          <Ratio aspectRatio='16x9'>
            <Carousel>{slider.map(this.renderSlider)}</Carousel>
          </Ratio>
          <QuickPay />
        </header>
        <main>
          <div className='movies-main'>
            <Tabs
              id='movies-tabs'
              defaultActiveKey='today'
              className='movies-tabs'
              transition
            >
              <Tab
                eventKey='today'
                title={i18n.t('today-cinema', { ns: 'home' })}
                transition
              >
                <MoviesToday movies={todayMovies} className={'in-home'} />
              </Tab>
              <Tab
                eventKey='soon'
                title={i18n.t('soon', { ns: 'home' })}
                transition
              >
                <MoviesToday movies={soonMovies} className={'in-home'} soon />
              </Tab>
            </Tabs>
          </div>
          <LatestNews items={lastNews} />
        </main>
      </div>
    )
  }

  renderSlider = poster => {
    return (
      <Carousel.Item key={poster.id}>
        <Link to={`/movies/${poster.id}`} className='poster-link'>
          <ApiImage src={poster.file_poster} className='img-poster' />
        </Link>
        <Carousel.Caption />
      </Carousel.Item>
    )
  }
}
