import numbro from 'numbro'
import { CURRENCY_SYMBOL } from './currency'

numbro.registerLanguage(
  {
    languageTag: 'ky-KY',
    delimiters: { thousands: ' ', decimal: '.', thousandsSize: 3 },
    abbreviations: {
      thousand: 'миң',
      million: 'млн',
      billion: 'млрд',
      trillion: 'трлн',
    },
    spaceSeparated: true,
    ordinal(num) {
      switch (num % 10) {
        case 0:
          return 'чу'
        case 1:
        case 2:
          return 'чи'
        case 3:
        case 4:
          return 'чү'
        case 5:
          return 'чи'
        case 6:
          return 'чы'
        case 7:
          return 'чи'
        case 8:
          return 'чи'
        case 9:
          return 'чу'
      }
      return ''
    },
    currency: {
      symbol: CURRENCY_SYMBOL,
      position: 'postfix',
      code: 'KGS',
    },
    currencyFormat: {
      thousandSeparated: true,
      totalLength: 0,
      spaceSeparated: true,
      average: false,
    },
    formats: {
      fourDigits: { totalLength: 4, spaceSeparated: true, average: false },
      fullWithTwoDecimals: {
        output: 'currency',
        mantissa: 2,
        spaceSeparated: true,
        thousandSeparated: true,
      },
      fullWithTwoDecimalsNoCurrency: { mantissa: 2, thousandSeparated: true },
      fullWithNoDecimals: {
        output: 'currency',
        spaceSeparated: true,
        thousandSeparated: true,
        mantissa: 0,
      },
    },
  },
  true,
)
