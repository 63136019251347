import { action, computed, observable } from 'mobx'
import requester from '../common/requester'

export default class AppState {
  @observable messages = observable.array([])
  @observable message = null
  @observable level = 'info'
  @observable wrapperClassName = ''

  tokenStorage = {}
  @observable token = null
  @observable authenticated = false
  @observable authenticating = true
  @observable user = null
  @observable card = null
  @observable account = null

  @observable warnAboutPin = false

  @observable platform = 'web'
  @observable notifications = observable.array([])
  @observable hasNotification = false
  @observable blockHasNotification = false

  @observable widget = observable.map()

  useTokenStorage(tokenStorage) {
    this.tokenStorage = tokenStorage
  }

  authenticate = async e => {
    e && e.preventDefault()
    this.setAuthenticating(true)
    try {
      await this.tryAuthenticate()
    } catch (e) {
      if (e && e.result === -4) this.setWarnAboutPin(true)
      this.setAuthenticated(false)
    } finally {
      this.setAuthenticating(false)
    }
  }

  tryAuthenticate = async () => {
    const user = {
      login: this.user.login,
      password: this.user.password,
      isWeb: this.tokenStorage.isWeb,
    }
    let { data } = await requester.post('/user/auth', user)
    this.isValidAuth(data)
    this.setToken(data.token)
    this.setUser(data.user)
    this.setCard(data.card)
    this.setAccount(data.account)
    this.tokenStorage.saveToken(data.token)
    this.setWarnAboutPin(false)
    this.clearAlert()
    this.setAuthenticated(true)
  }

  isValidAuth(data) {
    if (!this.tokenStorage.isWeb && !!data.user) {
      const msg = 'Неверные данные для входа'
      this.showWarning(msg)
      throw Error(msg)
    }
  }

  checkAuth = async () => {
    this.setAuthenticating(true)
    try {
      if (!this.token) this.setToken(await this.tokenStorage.loadToken())
      if (!this.token) return
      let response = await requester.post('/user/auth/check', {
        token: this.token,
      })
      this.setUser(response.data.user)
      this.setCard(response.data.card)
      this.setAccount(response.data.account)
      this.setAuthenticated(true)
    } catch (e) {
      this.logOut(e, [3, 4].includes(e.result))
    } finally {
      this.setAuthenticating(false)
    }
  }

  manualLogOut = e => {
    e && e.preventDefault()
    this.logOut({}, true)
  }

  logOut = (e, force = false) => {
    this.setToken(null)
    this.setUser(null)
    this.setCard(null)
    this.setAccount(null)
    if (force || [3, 4].includes(e?.result || 0)) {
      // 1: wrong auth params;
      // 3: blocked;
      this.tokenStorage.clearToken()
      this.tokenStorage.clearCard()
    }
    this.setAuthenticated(false)
    this.clearAlert()
  }

  @action.bound setUser(user) {
    this.user = user
  }

  @action setCard(card) {
    this.card = card
  }

  @action setAccount(account) {
    this.account = account
  }

  @action setToken(token) {
    this.token = token
  }

  @action setAuthenticated(authenticated) {
    this.authenticated = authenticated
  }

  @action setAuthenticating(authenticating) {
    this.authenticating = authenticating
  }

  @action unshiftNotification = notification => {
    if (
      !notification ||
      !notification.data ||
      !notification.data.title ||
      !notification.data.body
    )
      return
    this.notifications.unshift(notification)
    this.setHasNotification(true)
  }

  @action popNotification(notification) {
    if (typeof notification === 'number')
      this.notifications.splice(notification, 1)
    else this.notifications.remove(notification)
  }

  @action setHasNotification(status) {
    this.hasNotification = this.blockHasNotification ? false : status
  }

  @action setBlockHasNotification(status) {
    this.blockHasNotification = status
  }

  @action clearNotifications() {
    this.notifications.clear()
  }

  @observable busyCount = 0

  @action setBusyState(state) {
    this.busyCount += state ? 1 : -1
  }

  @computed
  get isBusy() {
    return this.busyCount > 0
  }

  @action showSuccess(message) {
    this.level = 'success'
    this.message = message
    this.pushMessage(this.message, this.level)
  }

  @action showError(message) {
    this.level = 'danger'
    this.message = message
    this.pushMessage(this.message, this.level)
  }

  @action showInfo(message) {
    this.level = 'info'
    this.message = message
    this.pushMessage(this.message, this.level)
  }

  @action showWarning(message) {
    this.level = 'warning'
    this.message = message
    this.pushMessage(this.message, this.level)
  }

  @action pushMessage(message, level) {
    this.messages.replace([
      {
        message: message,
        level: level,
      },
    ])
  }

  @action clearAlert() {
    this.message = null
    this.level = null
  }

  @action addWrapperClassName(className) {
    let classes = this.wrapperClassName.split(' ')
    if (classes.indexOf(className) === -1) classes.push(className)
    this.wrapperClassName = classes.join(' ')
  }

  @action removeWrapperClassName(className) {
    let classes = this.wrapperClassName.split(' ')
    let index = classes.indexOf(className)
    if (index > -1) classes.splice(index, 1)
    this.wrapperClassName = classes.join(' ')
  }

  async useWidget(key) {
    const { data } = await requester.get(`/widget/${key}`)
    this.setWidget(key, data.html)
  }

  @action
  setWidget(key, html) {
    this.widget.set(key, html)
  }

  @action setWarnAboutPin(status) {
    this.warnAboutPin = status
  }
}
