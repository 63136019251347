import moment from 'moment'
import React, { useEffect, useState } from 'react'
import requester from '../../../common/requester'

async function paymentCancel(payment_id) {
  while (true) {
    try {
      await requester.post(`/payment/cancel/${payment_id}`, {})
      break
    } catch (e) {
      const cancel_succeed = e.result || e.data.result
      if (cancel_succeed) break
      await new Promise(resolve => setTimeout(resolve, 300))
    }
  }
}

export default function PaymentCancel({ payment_id }) {
  const [expire, setExpire] = useState(
    moment().add(10, 'minutes').diff(moment(), 'seconds'),
  )
  useEffect(() => {
    let active = true
    if (!payment_id) return () => {}
    const timeout = setTimeout(
      () => active && paymentCancel(payment_id),
      moment().add(10, 'minutes').diff(moment(), 'milliseconds'),
    )
    return () => {
      clearTimeout(timeout)
      active = false
    }
  }, [payment_id])

  useEffect(() => {
    const due_date = moment().add(10, 'minutes')
    const id = setInterval(() => {
      setExpire(due_date.diff(moment(), 'seconds'))
    }, 1e3)
    return () => clearInterval(id)
  }, [payment_id])

  return (
    <div className='position-absolute top-0 end-0 p-2 bg-danger text-white bg-opacity-75'>
      Покупка будет отменена через:{' '}
      {moment().startOf('day').add(expire, 'seconds').format('mm:ss')}
    </div>
  )
}
