import { action, observable, computed } from 'mobx'
import requester from '../common/requester'

export default class QuickPayStore {
  @observable cinemas = observable.array([])
  @observable dates = observable.map({})
  @observable loadingRepertories = 0

  @observable active_cinema = null
  @observable active_date = null
  @observable focused_repertory = null

  @computed
  get isLoadingRepertories() {
    return this.loadingRepertories > 0
  }

  @action
  setLoadingRepertories = (inc = true) => {
    this.loadingRepertories += inc ? 1 : -1
  }

  fetchCinemas = async () => {
    const data = await requester.get('/cinema')
    this.setCinemas(data.data.list)
  }

  @action
  setCinemas(cinemas) {
    this.cinemas.replace(cinemas)
  }

  fetchDates = async cinema_id => {
    try {
      this.setLoadingRepertories()
      const data = await requester.get('/cinema/' + cinema_id + '/dates')
      this.setDates(cinema_id, data.data.list)
    } finally {
      this.setLoadingRepertories(false)
    }
  }

  @action
  setDates = (cinema_id, dates) => {
    if (this.dates.has(cinema_id)) this.dates.get(cinema_id).replace(dates)
    else this.dates.set(cinema_id, dates)
    if (
      this.active_cinema === cinema_id &&
      this.dates.get(cinema_id).length > 0 &&
      this.active_date === null
    )
      this.active_date = 0
  }

  @action
  toggleActiveCinema = cinema_id => {
    if (this.active_cinema === cinema_id) return this.clearActive()
    this.active_cinema = cinema_id
    if (
      !this.dates.has(this.active_cinema) ||
      this.dates.get(this.active_cinema).length === 0
    ) {
      this.fetchDates(cinema_id)
      this.active_date = null
    } else {
      this.active_date = 0
    }
  }

  @action
  setActiveDate = date => {
    this.active_date = date
  }

  @action
  clearActive = () => {
    this.active_cinema = null
    this.active_date = null
  }

  /**
   * @returns {{date: string, movies: Array}[]}
   */
  @computed
  get active_dates() {
    return this.active_cinema !== null && this.dates.has(this.active_cinema)
      ? this.dates.get(this.active_cinema)
      : []
  }

  /**
   * @returns {{
   *   id: number,
   *   c_id: number,
   *   m_id: number,
   *   cinema_id: number,
   *   hall_id: number,
   *   movie_id: number,
   *   movie: string,
   *   poster: string,
   *   details: ?Array
   * }[]}
   */
  @computed
  get active_repertories() {
    const dates = this.active_dates
    return this.active_date !== null && dates.length > this.active_date
      ? dates[this.active_date].repertories
      : []
  }

  @action
  focusOnRepertory = repertory => {
    this.focused_repertory = repertory
  }
}
