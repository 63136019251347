import classNames from 'classnames'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import React from 'react'
import { OverlayTrigger } from 'react-bootstrap'
import { FaInfoCircle, FaTimes } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { CURRENCY, Translation } from '../../common'
import { repertoryCalendar } from '../../common/utils'
import WideSelect from './admin/ui/WideSelect'
import { MovieType } from './movieComp/MoviesToday'
import ApiImage from './ui/ApiImage'
import { pricePopover } from './ui/PricePopover'

@inject('store')
@observer
export default class QuickPay extends React.Component {
  constructor(props) {
    super(props)
    this.store = this.props.store.quickPayStore
    this.store.fetchCinemas()
  }

  renderCinema = cinema => (
    <div
      key={cinema.id}
      className={classNames({
        qpm_active: cinema.id === this.store.active_cinema,
      })}
      onClick={() => this.store.toggleActiveCinema(cinema.id)}
    >
      {cinema.name.toUpperCase()}
      {cinema.imax ? <div className='imax-btn' /> : null}
    </div>
  )

  mapDates = (d, index) => ({
    value: index,
    label: moment(d.date, 'DD.MM.YY').calendar(null, repertoryCalendar),
  })

  repertoryLink = r =>
    `/cinema/${r.c_id}/${r.cinema_id}/${r.hall_id}/${r.movie_id}/${r.id}`

  renderRepertory = repertory => {
    const link = this.repertoryLink(repertory)
    return (
      <div
        key={link}
        className='qp_movies_item'
        onMouseOver={() => this.store.focusOnRepertory(repertory)}
      >
        <div>{repertory.time}</div>
        <Link to={`/movies/${repertory.m_id}`} className='qp_flex2'>
          {repertory.movie}
        </Link>
        <div className='qp_flex1'>{repertory.hall}</div>
        <div className='qp_flex1'>
          {parseInt(repertory.price)} {CURRENCY}
          {repertory.discount_1 || repertory.discount_2 ? (
            <OverlayTrigger
              trigger={['hover', 'focus']}
              placement='top'
              overlay={pricePopover(repertory)}
            >
              <span className='qp_discount'>
                <FaInfoCircle />
              </span>
            </OverlayTrigger>
          ) : null}
        </div>
        <div>
          <Link to={link}>
            <div className='qp_btn'>
              <Translation ns='quickpay'>{t => t('buy')}</Translation>
            </div>
          </Link>
        </div>
      </div>
    )
  }

  render() {
    const focused_repertory = this.store.focused_repertory
    const is_open =
      this.store.active_cinema !== null && this.store.active_date !== null
    return (
      <div className={classNames({ quick_pay: true, open: is_open })}>
        <div className='quick_pay_menu'>
          {this.store.cinemas.map(this.renderCinema)}
        </div>
        {is_open ? (
          <div className='quick_pay_body'>
            <div className='qp_main'>
              <FaTimes className='qp_close' onClick={this.store.clearActive} />
              <WideSelect
                label=''
                value={this.store.active_date}
                onSelect={index => this.store.setActiveDate(index)}
                options={this.store.active_dates.map(this.mapDates)}
              />
              <div className='qp_movies'>
                {this.store.active_repertories.map(this.renderRepertory)}
              </div>
            </div>
            <div className='qp_desc'>
              {focused_repertory ? (
                <div>
                  <div className={'qp_poster'}>
                    <ApiImage
                      src={focused_repertory.poster}
                      width={300}
                      height={430}
                      className={'qp-movie-poster'}
                    />
                    <MovieType movie={focused_repertory} />
                  </div>
                  <div className='qp_desc_title'>{focused_repertory.movie}</div>
                  {focused_repertory.details ? (
                    <div className='qp_desc_text'>
                      {focused_repertory.details.map(x =>
                        x.value ? (
                          <div key={x.title}>
                            <strong>
                              {x.title.endsWith(':') ? x.title : x.title + ':'}{' '}
                            </strong>
                            {x.value}
                          </div>
                        ) : null,
                      )}
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}
