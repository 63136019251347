/**
 * Created by Aibek on 13.07.2017.
 */
import { action, observable } from 'mobx'
import requester from '../common/requester'
import autobind from 'autobind-decorator'
import BaseStore from './BaseStore'

export default class SubscribeStore extends BaseStore {
  @observable subscriber = {
    email: null,
    full_name: null,
  }
  @observable warningMessage = null
  @observable completeMessage = null
  @observable reCaptcha = ''

  @action resetSubscriber() {
    this.subscriber = {
      email: null,
      full_name: null,
    }
    this.reCaptcha = ''
  }

  @action setWarning(msg) {
    this.warningMessage = msg
  }

  @action setComplete(msg) {
    this.completeMessage = msg
  }

  @autobind
  async subscribeMe(e, isMobile) {
    try {
      this.setComplete(null)
      this.setWarning(null)
      let result = await requester.post('/subscribe', {
        reCaptcha: this.reCaptcha,
        isMobile: isMobile,
        ...this.subscriber,
      })
      this.resetSubscriber()
      result.data.message && this.setComplete(result.data.message)
      return result
    } catch (e) {
      this.setWarning(e.message || e)
      throw e
    }
  }

  async startJob() {
    await requester.get('/admin/run/newsletter')
  }

  async unsubscribe(email, encrypt) {
    try {
      let result = await requester.get(`/subscribe/${email}/${encrypt}`)
      this.setWarning(null)
      result.data.message && this.setComplete(result.data.message)
      return result
    } catch (e) {
      this.setWarning(e.message || e)
      throw e
    }
  }
}
