import autobind from 'autobind-decorator'
import React, { Component } from 'react'
import { Collapse, Image } from 'react-bootstrap'
import { Translation } from 'react-i18next'
import { MdClose } from 'react-icons/md'
import { COUNTRY } from '../../../common/country'
import app_store from '../../images/app_store.png'
import google_play from '../../images/google-play.png'

export default class MobileVersion extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      os: 'web',
    }
  }

  componentDidMount() {
    if (window.localStorage.getItem('mobile-dismissed') === 'true') return
    if (window.navigator.userAgent.includes('Android')) {
      this.setState({ show: true, os: 'android' })
    } else if (window.navigator.userAgent.includes('iPhone')) {
      this.setState({ show: true, os: 'ios' })
    }
  }

  render() {
    return (
      <Collapse in={this.state.show}>
        <div>
          <div className='mobile-version'>
            <MdClose size={24} onClick={this.hideModal} />
            {this.state.os === 'ios' ? (
              <div>
                <span>
                  <Translation>{t => t('appstore')}</Translation>
                </span>
                <a
                  href={
                    COUNTRY === 'kg'
                      ? 'https://apps.apple.com/kg/app/cinematica/id1283100195'
                      : 'https://apps.apple.com/uz/app/cinematica-uz/id6474292866'
                  }
                >
                  <Image src={app_store} alt='ios' />
                </a>
              </div>
            ) : (
              <div>
                <span>
                  <Translation ns={'payment'}>
                    {t => t('playmarket')}
                  </Translation>
                </span>
                <a
                  href={`https://play.google.com/store/apps/details?id=${COUNTRY}.cinematica.app`}
                >
                  <Image src={google_play} alt='android' />
                </a>
              </div>
            )}
          </div>
        </div>
      </Collapse>
    )
  }

  @autobind
  hideModal() {
    window.localStorage.setItem('mobile-dismissed', 'true')
    this.setState({ show: false })
  }
}
