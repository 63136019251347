import { action, observable } from 'mobx'
import requester from '../common/requester'
import BaseStore from './BaseStore'

export default class FeedbackStore extends BaseStore {
  @observable feedback = { email: '', message: '' }
  @observable warningMessage = null
  @observable completeMessage = null
  @observable reCaptcha = ''

  @action resetFeedback() {
    this.feedback = { email: '', message: '' }
    this.reCaptcha = ''
  }

  @action setWarning(msg) {
    this.warningMessage = msg
  }

  @action setComplete(msg) {
    this.completeMessage = msg
  }

  sendFeedback = async (_, isMobile = false) => {
    this.setComplete(null)
    this.setWarning(null)
    const data = { reCaptcha: this.reCaptcha, isMobile, ...this.feedback }
    let result
    try {
      result = await requester.post('/feedback', data)
    } catch (e) {
      this.setWarning(e.message || e)
      return e
    }
    this.resetFeedback()
    result.data.message && this.setComplete(result.data.message)
    return result
  }
}
