import autobind from 'autobind-decorator'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { Button } from 'react-bootstrap'

@inject('store')
@observer
export default class WideSelect extends React.Component {
  render() {
    let { label, options, value } = this.props
    return (
      <div className='wide-select'>
        {!!label && <label>{label}:</label>}
        {options.map((o, index) => (
          <Button
            key={index}
            variant={o.value === value ? 'primary' : 'outline-primary'}
            size='sm'
            value={index}
            onClick={this.selectOption}
          >
            {o.label}
          </Button>
        ))}
      </div>
    )
  }

  @autobind selectOption(e) {
    let { options, onSelect } = this.props
    if (onSelect && typeof onSelect === 'function')
      onSelect(options[e.target.value].value)
  }
}
