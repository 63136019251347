/**
 * Created by jaynakus on 3/15/17.
 */
import {
  action,
  autorun,
  observable,
  computed,
  observe,
  isObservableArray,
} from 'mobx'

export default class BaseModel {
  store

  constructor(store) {
    this.store = store
    if (process.env.NODE_ENV === 'development') {
      this.initMock()
    }
  }

  @action initMock() {
    this.initChildMock()
  }

  @action set(field, value) {
    this[field] = value
  }

  @action get(field) {
    return this[field]
  }

  toJson() {
    let data = Object.keys(this).reduce((val, k) => {
      let o = {}
      if (this[k] instanceof BaseModel) {
        // o[k] = this[k].toJson();
      } else if (isObservableArray(this[k])) {
        o[k] = this[k].splice()
      } else if (typeof this[k] !== 'function') {
        o[k] = this[k]
      }
      if (
        o[k] !== undefined &&
        k !== 'store' &&
        this.storedFields().indexOf(k) >= 0
      ) {
        val.push(o)
      }
      return val
    }, [])
    return data
  }

  fromJson(data) {
    Object.keys(this).forEach(k => {
      if (data[k] && this.storedFields().indexOf(k) >= 0) {
        this[k] = data[k]
      }
    })
  }

  initChildMock() {}
}
