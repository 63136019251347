async function saveToken(token) {
  return !window.localStorage.setItem('token', token) || true
}

async function loadToken() {
  return window.localStorage.getItem('token')
}

async function clearToken() {
  return !window.localStorage.removeItem('token') || true
}

async function saveCard(code, pin) {
  return false
}

async function loadCard() {
  return null
}

async function clearCard() {
  return false
}

export const tokenStorage = {
  isWeb: true,
  saveToken,
  loadToken,
  clearToken,
  saveCard,
  loadCard,
  clearCard,
}
