import { Buffer } from 'buffer'
import { extendObservable, isObservableMap, runInAction } from 'mobx'
import { API_URL } from './api-url'
import { i18n } from './i18n'

export const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    let k = key
    if (Array.isArray(key)) {
      key.forEach(i => {
        if (x[i]) k = i
      })
    }
    ;(rv[x[k]] = rv[x[k]] || []).push(x)
    return rv
  }, {})
}

export function apiUrl(url) {
  return !url || /^(blob:)?https?:\/\//.test(url) ? url : API_URL + url
}

export function imageUrl(url, width, height) {
  url = apiUrl(url)
  const resize =
    url &&
    url.startsWith(API_URL) &&
    width &&
    height &&
    /\.(jpe?g|png)$/i.test(url)
  return resize ? `${url}/${width}/${height}` : url
}

export function seatsToParams(seats) {
  const seats_json = JSON.stringify(
    seats.sort((a, b) =>
      a.row > b.row ? 1 : a.row < b.row ? -1 : a.number - b.number,
    ),
  )
  return { seats: Buffer(seats_json, 'utf8').toString('base64') }
}

export function seatsFromParams(params) {
  try {
    return JSON.parse(Buffer(params.seats, 'base64').toString('utf8'))
  } catch {
    return []
  }
}

export function seatsString(seats, inline = false) {
  let rows = {}
  for (const seat of seats) {
    if (seat.row in rows) rows[seat.row].push(1 * seat.number)
    else rows[seat.row] = [1 * seat.number]
  }
  let seats_string = []
  for (let row in rows) {
    seats_string.push(
      `${i18n.t('row', { ns: 'reservation' })} - ${row}, ${
        rows[row].length === 1
          ? i18n.t('seat', {
              ns: 'reservation',
            })
          : i18n.t('seats', {
              ns: 'reservation',
            })
      } - ${rows[row].sort((a, b) => a - b).join(', ')}`,
    )
  }
  return seats_string.join(inline ? ' • ' : '\n')
}

export function isValidEmail(email = '', trim = true) {
  return (
    (trim ? email.trim() : email).match(
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
    ) !== null
  )
}

export function setModelValue(model, name, value) {
  if (model && name) {
    runInAction(() => {
      if (isObservableMap(model)) {
        model.set(name, value)
      } else if (!model.hasOwnProperty(name)) {
        let data = {}
        data[name] = value
        extendObservable(model, data)
      } else {
        model[name] = value
      }
    })
  }
}

export function getModelValue(model, name, defaultValue) {
  if (model && (name || typeof name === 'number')) {
    let value
    if (isObservableMap(model)) {
      value = model.get(name)
    } else {
      value = model[name]
    }
    if (value === undefined || value === null) return defaultValue
    return value
  }
  return defaultValue
}

export function getValue(model, name, value) {
  let v = value
  if (model && (name || typeof name === 'number')) {
    if (typeof name === 'string' && name.indexOf('.') > -1) {
      let [obj, ...key] = name.split('.')
      if (model[obj]) v = model[obj][key.join('.')]
    } else {
      v = model[name]
    }
  }
  return !v && v !== 0 ? '' : v
}

const file_sizes = {
  gb: 1024 * 1024 * 1024,
  mb: 1024 * 1024,
  kb: 1024,
}

export function fileSize(size) {
  if (size < 1024) return size.toFixed(0) + ' B'
  else if (size > file_sizes.gb)
    return ((size / file_sizes.gb) * 1000).toFixed(0) / 1000 + ' GB'
  else if (size > file_sizes.mb)
    return ((size / file_sizes.mb) * 1000).toFixed(0) / 1000 + ' MB'
  else if (size > file_sizes.kb)
    return ((size / file_sizes.kb) * 1000).toFixed(0) / 1000 + ' KB'
}

export const repertoryCalendar = {
  sameDay: `[${i18n.t('today-lower')}]`,
  nextDay: `[${i18n.t('tomorrow-lower')}]`,
  nextWeek: 'DD.MM',
  lastDay: `[${i18n.t('yesterday-lower')}]`,
  lastWeek: 'DD.MM',
  sameElse: 'DD.MM',
}
