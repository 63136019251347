import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Provider } from 'mobx-react'
import moment from 'moment'
import 'moment/locale/ru'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { initialize } from '../common'
import stores from '../stores/stores'
import App from './components/App'
import { AppHelmet } from './components/Helmet'
import reportWebVitals from './reportWebVitals'
import './styles/main.scss'
import { tokenStorage } from './utils'
import './gtag'

moment.locale('ru')
const store = stores()
store.appState.useTokenStorage(tokenStorage)
initialize(store.appState)

function Web() {
  return (
    <>
      <AppHelmet />
      <MuiPickersUtilsProvider utils={MomentUtils} locale='ru'>
        <Provider store={store}>
          <Router>
            <App />
          </Router>
        </Provider>
      </MuiPickersUtilsProvider>
    </>
  )
}

createRoot(document.getElementById('root')).render(<Web />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
