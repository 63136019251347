import React from 'react'
import { COUNTRY } from './country'

export const CURRENCY_SYMBOL = COUNTRY === 'kg' ? 'с' : 'soʻm'

export const CURRENCY =
  COUNTRY === 'kg' ? (
    <span className='price-som'>{CURRENCY_SYMBOL}</span>
  ) : (
    <span>{CURRENCY_SYMBOL}</span>
  )
