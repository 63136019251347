import axios from 'axios'
import * as base64 from 'base-64'
import { i18n } from './i18n'

export { API_URL } from './api-url'

let _appState

export function initialize(appState) {
  _appState = appState
}

axios.interceptors.request.use(
  function (config) {
    if (!config.url.includes('payment/status')) {
      if (!(config.url.includes('poll') && _appState.busyCount > 0)) {
        _appState.setBusyState(true)
      }
    }
    if (_appState.token) {
      config.headers.Authorization =
        'Basic ' + base64.encode('web:' + _appState.token)
    }
    config.headers['X-Language'] = i18n.language.split('-')[0]
    return config
  },
  function (error) {
    _appState.setBusyState(false)
    _appState.showError(error.message || error)
    return Promise.reject(error)
  },
)

axios.interceptors.response.use(
  function (response) {
    const { config, data } = response
    if (!config.url.includes('poll') && !data.job_id) {
      _appState.setBusyState(false)
    } else if (data.status > 0) {
      _appState.setBusyState(false)
      if (data.status === 2) {
        _appState.showError(data.message)
        return Promise.reject(data)
      }
    }
    if (data.result < 0) {
      _appState.showError(data.message)
      return Promise.reject(data)
    } else if (data.result > 0) {
      const auth_error = [3, 4].includes(data.result)
      !_appState.authenticating && auth_error && _appState.logOut(data)
      !auth_error && _appState.showWarning(data.message)
      return Promise.reject(data)
    }
    return response
  },
  function (error) {
    _appState.setBusyState(false)
    _appState.showError(error.message || error)
    return Promise.reject(error)
  },
)
